import {
  Button,
  Grid, Modal,
  TextField, Typography,
  withStyles,
} from '@material-ui/core';
import PropTypes from "prop-types";
import React from "react";
import SmsUtils from "../../components/utils/SmsUtils";

const styles = ({ spacing, palette }) => ({
  accountWrap: {
    border: "1px solid #E5E5E5",
    backgroundColor: "#F6F6F6",
    boxShadow: "0 2px 7px 0 rgba(0,0,0,0.5)",
    margin: spacing.unit * 3,
    padding: spacing.unit * 3
  },
  accountLabelField: {
    color: palette.text.secondary,
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  accountTextField: {
    padding: `0 ${spacing.unit}px`,
    border: "1px solid #E7E7E7",
    borderRadius: "3px",
    background: "#FBFBFB!important",
    "&::after": {
      display: "none"
    },
    "&::before": {
      display: "none"
    },
    "&> div > div:focus": {
      background: "#FBFBFB"
    }
  },
  accountRow: {
    marginBottom: spacing.unit
  },
  paper: {
    position: 'absolute',
    width: spacing.unit * 60,
    backgroundColor: palette.background.paper,
    padding: spacing.unit * 4,
    outline: 'none',
  },
  container: {
    marginBottom: 18
  },
  button: {
    marginRight: spacing.unit * 2
  },
});

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

class PractitionerSMS extends React.Component {
  static propTypes = {
    practitioner: PropTypes.object.isRequired,
    classes: PropTypes.object,
    save: PropTypes.func.isRequired,
    onError: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      smsNumber: '',
      areaCode: '',
      areaCodeError: false,
      showModalRemove: false,
      showModalAdd: false,
    };
  }

  async componentDidMount() {
    try {
      const smsNumber = await SmsUtils.getSmsNumber(
        this.props.practitioner.uid
      );

      this.setState({ smsNumber: smsNumber.phoneNumber });
    } catch (error) {
      this.setState({ smsNumber: '' });
      // no number found
      // console.log(error);
    }
  }

  addSmsNumber = async () => {
    try {
      const smsNumber = await SmsUtils.createSmsNumber(
        this.props.practitioner.uid,
        this.state.areaCode,
      );
      this.setState({
        showModalAdd: false,
        areaCode: '',
        smsNumber: smsNumber.phoneNumber,
      });
      this.handleModalAddClose();
    } catch (error) {
      this.props.onError(
        error.message,
      );
    }
  };

  removeSmsNumber = async () => {
    try {
      await SmsUtils.removeSmsNumber(
        this.props.practitioner.uid
      );
      this.setState({
        showModalRemove: true,
        smsNumber: ''
      });
      this.handleModalRemoveClose();
    } catch (error) {
      this.props.onError(
        error.message,
      );
    }
  };

  handleModalRemoveOpen = () => {
    this.setState({
      showModalRemove: true,
    });
  };
  handleModalRemoveClose = () => {
    this.setState({
      showModalRemove: false,
    });
  };

  handleModalAddOpen = () => {
    this.setState({
      showModalAdd: true,
    });
  };
  handleModalAddClose = () => {
    this.setState({
      showModalAdd: false,
    });
  };

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({
      areaCode: value,
      areaCodeError: !value.match(/^$|^\d{3}$/),
    })
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.accountWrap} direction="column">
        <TextField
          className={classes.accountRow}
          label="Direct Health SMS Number"
          value={this.state.smsNumber}
          InputLabelProps={{
            shrink: true,
            className: classes.accountLabelField
          }}
          InputProps={{
            className: classes.accountTextField,
            style: { display: this.state.smsNumber ? 'block' : 'none' },
            readOnly: true
          }}
        />
        {this.state.smsNumber && (
          <Button
            key="btn"
            variant="outlined"
            color="primary"
            onClick={this.handleModalRemoveOpen}
          >
            REMOVE
          </Button>
        )}
        {!this.state.smsNumber && (
          <Button
            key="btn"
            variant="outlined"
            color="primary"
            style={{ marginTop: 20 }}
            onClick={this.handleModalAddOpen}
          >
            ADD SMS NUMBER
          </Button>
        )}
        <Modal
          onClose={this.handleModalRemoveClose}
          open={this.state.showModalRemove}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <Grid className={classes.container} container>
              <Typography variant='h6'>Please Confirm</Typography>
              <div>Are you sure you want to remove this number? You will not be able to undo this operation.</div>
            </Grid>
            <Grid className={classes.container} container
              direction="row"
              justify="flex-end"
              style={{ marginBottom: 0 }}>
                <Button className={classes.button} onClick={this.handleModalRemoveClose}>Cancel</Button>
                <Button variant='contained' color='primary' onClick={this.removeSmsNumber}>Remove</Button>
            </Grid>
          </div>
        </Modal>

        <Modal
          onClose={this.handleModalAddClose}
          open={this.state.showModalAdd}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <Grid className={classes.container} container>
              <Typography variant='h6'>Choose Preferred Phone Area Code</Typography>
              <div>Enter the provider&#39;s preferred area code to create Direct Health number.</div>
              <TextField
                id='summary'
                label='Enter 3-digit area code'
                variant='filled'
                onChange={this.handleChange}
                error={this.state.areaCodeError}
                required
                fullWidth
              />
            </Grid>
            <Grid className={classes.container} container
              direction="row"
              justify="flex-end"
              style={{ marginBottom: 0 }}>
              <Button className={classes.button} onClick={this.handleModalAddClose}>Cancel</Button>
              <Button variant='contained' color='primary' onClick={this.addSmsNumber} disabled={this.state.areaCodeError}>Submit</Button>
            </Grid>
          </div>
        </Modal>
      </Grid>
    );
  }
}

export default withStyles(styles)(PractitionerSMS);
