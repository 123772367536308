import {
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableRow
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import * as helpers from "../../utils/helpers";
import {
  getPracticesByPractitionerID,
  getPractitionerByID,
  getSubscriptionHistory,
  getSubscriptionStatus
} from "../practitioners/PractitionerUtils";
import { getApiUrl} from '../utils/NetworkUtils';
import { getSpecialties } from "../specialties/SpecialtyUtils";
import Loading from "../Loading";

const styles = {
  appBar: {
    position: "relative",
    minWidth: '500px'
  },
  flex: {
    flex: 1
  }
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class PractitionerDialog extends React.Component {
  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleOpenContacts: PropTypes.func.isRequired,
    uid: PropTypes.string
  };

  state = {
    loading: true,
    open: false,
    practitioner: null,
    error: null
  };

  loadData = async uid => {
    try {
      const response = await Promise.all([
        getPractitionerByID(uid),
        getSubscriptionStatus(uid),
        getSubscriptionHistory(uid),
        getPracticesByPractitionerID(uid),
        getSpecialties()
      ]);
      const practitioner = response[0];

      const practices = response[3].map(value => value.name).join(", ");

      const specialties = response[4];
      const specialtyValues = [];
      practitioner.specialties.forEach(specialtyUid => {
        specialties.forEach(specialty => {
          if (specialty.uid === specialtyUid) {
            specialtyValues.push(specialty.name);
          }
        });
      });

      this.setState({
        practitioner,
        subscription: response[1],
        isCancelSubscription: response[1].status === "canceled",
        subscriptionHistory: response[2].expiredSubscriptions,
        practices,
        specialties: specialtyValues.join(", "),
      });
    } catch (error) {
      this.setState({ error });
    }

    this.setState({ loading: false });
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.uid) {
      this.setState({ open: false });
      return;
    }

    if (nextProps.uid !== this.props.uid) {
      this.setState({ loading: true, open: true });
      this.loadData(nextProps.uid);
    }
  }

  renderRow = (label, value) => {
    if (!value && typeof value !== "boolean") {
      return null;
    }

    return (
      <TableBody>
        <TableRow>
          <TableCell>{label}</TableCell>
          <TableCell>{value.toString()}</TableCell>
        </TableRow>
      </TableBody>
    );
  };

  renderPhoto = () => {
    const url = `${getApiUrl()}/practitioners/${
      this.state.practitioner.uid
    }/picture?w=50`;

    return (
      <TableBody>
        <TableRow>
          <TableCell>Photo</TableCell>
          <TableCell>
            <img src={url} />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  renderTypography = (value, type = "h6") => (
    <TableBody>
      <TableRow>
        <TableCell colSpan="2">
          <Typography variant={type}>{value}</Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  );

  render() {
    const { classes } = this.props;

    let content = <Loading />;

    if (!this.state.loading) {
      const location = helpers.locationToString(
        this.state.practitioner.location
      );
      content = (
        <div>
          <Table>
            {this.renderPhoto()}
            {this.renderRow(
              "Date Created",
              helpers.renderDate(this.state.practitioner.creationDate)
            )}
            {this.renderRow("Country", this.state.practitioner.country)}
            {this.renderRow("Language", this.state.practitioner.language)}
            {this.renderRow("Title", this.state.practitioner.title)}

            {this.renderRow("First Name", this.state.practitioner.firstname)}
            {this.renderRow("Last Name", this.state.practitioner.lastname)}
            {this.renderRow("Direct Health ID", this.state.practitioner.uid)}
            {this.renderRow("Date of Birth", this.state.practitioner.birthdate)}
            {this.renderRow("Phone", this.state.practitioner.phone)}
            {this.renderRow("Email", this.state.practitioner.email)}

            {this.renderRow("Invite Code", this.state.practitioner.inviteCode)}
            {this.renderRow(
              "National Provider Identifier",
              this.state.practitioner.npiNumber
            )}
            {this.renderRow("DEA number", this.state.practitioner.deaNumber)}
            {this.renderRow(
              "Registration ID",
              this.state.practitioner.registrationId
            )}
            {this.renderRow(
              "Practice Number",
              this.state.practitioner.practiceNumber
            )}

            {this.renderRow("Available", this.state.practitioner.available)}
            {this.renderRow(
              "Verified",
              this.state.practitioner.verifiedPractitioner
            )}
            {this.renderRow(
              "Is Demo/Sales account",
              this.state.practitioner.demo
            )}
            {this.renderRow(
              "e-Prescribe/Can Prescribe",
              this.state.practitioner.canPrescribe
            )}
            {this.renderRow(
              "Dosespot Registered",
              this.state.practitioner.dosespotRegistered
            )}

            {this.state.practitioner.responseTime &&
              this.renderRow(
                "Response Time",
                `${this.state.practitioner.responseTime.time}-${
                  this.state.practitioner.responseTime.unit
                }`
              )}

            {this.state.specialties &&
              this.renderRow("Specialty", this.state.specialties)}
            {this.state.practices &&
              this.renderRow("Practices", this.state.practices)}

            {this.renderRow("EHR", this.state.practitioner.selectedEhr)}

            {this.renderTypography("Subscription")}
            {this.renderRow("Subscription Plan", this.state.subscription.plan)}
            {this.state.subscription.plan === "premium" && (
              <React.Fragment>
                {this.renderRow(
                  "Activated at",
                  helpers.renderDate(this.state.subscription.activatedAt)
                )}
                {this.renderRow(
                  "Period end date",
                  helpers.renderDate(this.state.subscription.periodEndsAt)
                )}
                {this.renderRow("Status", this.state.subscription.status)}
              </React.Fragment>
            )}
            {this.state.subscriptionHistory.length
              ? this.state.subscriptionHistory.map((item, index) => (
                  <React.Fragment key={index}>
                    {this.renderTypography(
                      `Previous subscription #${index + 1}`,
                      "subtitle1"
                    )}
                    {this.renderRow(
                      "Activated at",
                      helpers.renderDate(this.state.subscription.activatedAt)
                    )}
                    {this.renderRow(
                      "Expired at",
                      helpers.renderDate(this.state.subscription.periodEndsAt)
                    )}
                  </React.Fragment>
                ))
              : this.renderTypography(
                  "Subscription history is empty",
                  "subtitle1"
                )}

            {this.renderTypography("Pricing Information")}
            {this.renderRow(
              "Default currency",
              this.state.practitioner.currency
            )}
            {this.renderRow(
              "Incentive Amount",
              this.state.practitioner.incentivePool &&
                this.state.practitioner.incentivePool.amout
            )}
            {this.renderRow(
              "Credit Per Patient",
              this.state.practitioner.incentivePerPatient &&
                this.state.practitioner.incentivePool.incentivePerPatient
            )}

            {this.state.practitioner.pricingModel && (
              <React.Fragment>
                {this.renderTypography("Payment Model", "subtitle1")}
                {this.renderRow(
                  "Type",
                  this.state.practitioner.pricingModel.type
                )}
                {this.renderRow(
                  "Amount",
                  this.state.practitioner.pricingModel.amount / 100
                )}
                {this.renderRow(
                  "Currency",
                  this.state.practitioner.pricingModel.currency
                )}
              </React.Fragment>
            )}

            {location && (
              <React.Fragment>
                {this.renderTypography("Location Information")}
                {this.renderRow("Address", location)}
                {this.renderRow(
                  "Additional Info",
                  this.state.practitioner.location.additionalInfos
                )}
              </React.Fragment>
            )}

            {this.state.practitioner.biography && (
              <React.Fragment>
                {this.renderTypography('Biography Information')}
                {this.state.practitioner.biography.education && this.state.practitioner.biography.education.map((education, index) =>
                  this.renderRow(
                    `Education #${index + 1}`,
                    `${education.summary} - ${education.body} (${moment(education.start).format('MMM YYYY')} - ${moment(education.end).format('MMM YYYY')})`
                  )
                )}
                {this.state.practitioner.biography.experiences && this.state.practitioner.biography.experiences.map((exp, index) =>
                  this.renderRow(
                    `Experience #${index + 1}`,
                    `${exp.summary} - ${exp.body} (${moment(exp.start).format('MMM YYYY')} - ${exp.current ? 'present' : moment(exp.end).format('MMM YYYY')})`
                  )
                )}
                {this.renderRow('Welcome Message', this.state.practitioner.biography.welcomeMessage)}
              </React.Fragment>
            )}
          </Table>
        </div>
      );
    }

    return (
      <Dialog
        open={this.state.open}
        onClose={() => this.props.handleClose(false)}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={() => this.props.handleClose(false)}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Doctor
            </Typography>
            <Button color="inherit" onClick={() => this.props.handleOpenContacts()}>
              Contacts
            </Button>
            <Button
              color="inherit"
              onClick={() => this.props.handleClose(true)}
            >
              Edit
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>{content}</DialogContent>
      </Dialog>
    );
  }
}

PractitionerDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PractitionerDialog);
