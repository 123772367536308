import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { withStyles, Grid, Typography, Table, TableBody, TableRow, TableCell, TextField, Button } from '@material-ui/core';
import { connect} from 'react-redux';

import {
  getEtlUrl,
  getEHRInstances,
  getLinkedPractitioners,
  getMatchingEhrPhysicianByNpi,
  manuallyLinkPractitioner,
  unlinkPractitioner,
  startSync,
  pauseSync,
  updateTitle,
} from './EHRManagementUtils';
import { getPractitionerByID } from './../../components/practitioners/PractitionerUtils';

import Loading from '../../components/Loading';
import AutoComplete from './../../components/autocomplete/AutoComplete';
import {
  setNotifyHidden,
  setNotifySuccess,
  setNotifyWarning
} from '../../components/notification/NotifyUtils';

const styles = theme => ({
  container: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  row: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    display: 'flex',
  },
  title: {
    fontWeight: 'bold',
    marginRight: theme.spacing.unit,
    paddingTop: theme.spacing.unit,
  },
  wide: {
    width: '50%',
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  buttonsCell: {
    width: '210px',
    textAlign: 'right',
  },
  loadingWrap: {
    height: '100%',
  },
  menu: {
    width: 200,
  },
  centered: {
    textAlign: 'center',
  },
});

class EHRInstanceDetails extends React.Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    params: PropTypes.shape({
      sourceKey: PropTypes.string.isRequired,
    }),
    setNotifyHidden: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
    setNotifySuccess: PropTypes.func.isRequired,
  };

  state = {
    ehrInstance: {},
    physicians: [],
    linkedPractitioners: [],
    practitionerUid: '',
    practitioner: null,
    error: false,
    errorMessage: '',
    loading: false,
    isEditTitle: false,
    title: '',
    titleError: false,
    isSelectingPhysician: false,
    selectedPhysician: null,
    searchValue: '',
    isLinkingNew: false,
  };

  onChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
      error: false,
      errorMessage: '',
      titleError: false,
    });
  };

  onClickCancelLink = () => {
    this.setState({
      isLinkingNew: false,
      practitionerUid: '',
      isSelectingPhysician: false,
      physicians: [],
    });
  };

  onLinkPractitioner = async () => {
    const { sourceKey } = this.props.params;

    if (this.state.practitionerUid) {
      this.setState({ loading: true });
      try {
        const practitioner = await getPractitionerByID(this.state.practitionerUid);
        const npi = practitioner.npiNumber || '';
        const result = await getMatchingEhrPhysicianByNpi(this.state.etlUrl, sourceKey, npi);
        if (result.npiMatched) {
          this.setState({
            practitioner,
            selectedPhysician: result.physicians[0],
          });
          this.onClickManualLink();
        } else {
          this.setState({
            practitioner,
            physicians: result.physicians,
            isSelectingPhysician: true,
            loading: false,
          });
        }
      } catch (e) {
        this.setState({
          error: true,
          errorMessage: e.message,
          loading: false,
        });
      }
    } else {
      this.setState({ error: true });
    }
  };

  onClickSync = () => {
    startSync(this.state.etlUrl, this.props.params.sourceKey)
      .then((ehrInstance) => {
        this.setState({ ehrInstance });
        this.props.setNotifySuccess('Start sync request sent');
      })
      .catch(e => this.props.setNotifyWarning(e.message));
  };

  onClickPauseSync = () => {
    pauseSync(this.state.etlUrl, this.props.params.sourceKey)
      .then((ehrInstance) => {
        this.setState({ ehrInstance });
        this.props.setNotifySuccess('Pause sync request sent');
      })
      .catch(e => this.props.setNotifyWarning(e.message));
  };

  onClickEditTitle = () => {
    this.setState({
      isEditTitle: true,
      title: this.state.ehrInstance.title,
    });
  };

  onSaveTitle = () => {
    if (!this.state.title) {
      return this.setState({ titleError: true });
    }
    updateTitle(this.state.etlUrl, this.props.params.sourceKey, this.state.title)
      .then((ehrInstance) => {
        this.setState({
          ehrInstance,
          isEditTitle: false,
        });
      })
      .catch((e) => {
        this.props.setNotifyWarning(e.message);
      });
  };

  onSelectPhysician = (physician) => {
    this.setState({
      selectedPhysician: physician,
    });
  };

  onClickManualLink = () => {
    const { sourceKey } = this.props.params;
    const { physicianId, prettyName } = this.state.selectedPhysician;

    manuallyLinkPractitioner(
      sourceKey,
      this.state.practitionerUid,
      physicianId,
      prettyName,
    ).then(() => {
      Promise.all([
        getEHRInstances(this.state.etlUrl),
        getLinkedPractitioners(sourceKey),
      ])
        .then((result) => {
          this.setState({
            ehrInstance: result[0].ehrInstances.find(item => item.sourceKey === sourceKey),
            linkedPractitioners: result[1].linkedPractitioners,
            isSelectingPhysician: false,
            isLinkingNew: false,
            loading: false,
          });
          if (result[1].linkedPractitioners.length &&
            !result[0].ehrInstances.find(item => item.sourceKey === sourceKey).syncStarted) {
            this.onClickSync();
          }
        })
        .catch((e) => {
          this.setState({ loading: false });
          this.props.setNotifyWarning(e.message);
        });
    }).catch((e) => {
      this.setState({ loading: false });
      this.props.setNotifyWarning(e.message);
    });
  };

  onSearch = (searchValue) => {
    this.setState({ searchValue });
    const inputValue = searchValue.trim().toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return new Promise((resolve) => {
      const results = inputLength === 0
        ? []
        : this.state.physicians.filter((suggestion) => {
          let keep = false;
          if (count < 10) {
            if (suggestion.firstName.slice(0, inputLength).toLowerCase() === inputValue ||
              suggestion.lastName.slice(0, inputLength).toLowerCase() === inputValue) {
              keep = true;
            } else if (suggestion.npi && suggestion.npi.slice(0, inputLength) === inputValue) {
              keep = true;
            }
          }

          if (keep) {
            Object.assign(suggestion, {
              title: `${suggestion.prettyName}${suggestion.inactive ? ' (Inactive)' : ''}`,
              subTitle: `${suggestion.city}, ${suggestion.state}, npi: ${suggestion.npi || ''}`,
            });
            count += 1;
          }

          return keep;
        });

      resolve(results);
    });
  };

  onClear = () => {
    this.setState({
      selectedPhysician: null,
    });
  };

  onUnlink = async (index) => {
    const { practitionerUid } = this.state.linkedPractitioners[index];
    const { sourceKey } = this.props.params;
    this.setState({ loading: true });
    try {
      await unlinkPractitioner(sourceKey, practitionerUid);
      const result = await getLinkedPractitioners(sourceKey);
      this.setState({
        linkedPractitioners: result.linkedPractitioners,
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
      this.props.setNotifyWarning(e.message);
    }
  };

  componentWillMount() {
    const { sourceKey } = this.props.params;
    this.setState({ loading: true });
    getEtlUrl()
      .then((etlRes) => {
        Promise.all([
          getEHRInstances(etlRes.endPoint),
          getLinkedPractitioners(sourceKey),
        ])
          .then((result) => {
            this.setState({
              ehrInstance: result[0].ehrInstances.find(item => item.sourceKey === sourceKey),
              linkedPractitioners: result[1].linkedPractitioners,
              etlUrl: etlRes.endPoint,
              loading: false,
            });
          })
          .catch((e) => {
            this.setState({ loading: false });
            this.props.setNotifyWarning(e.message);
          });
      })
      .catch((e) => {
        this.setState({ loading: false });
        this.props.setNotifyWarning(e.message);
      });
  }

  render() {
    const { classes } = this.props;
    const defaultErrorMsg = 'This field cannot be blank';

    if (this.state.loading) {
      return (
        <Grid container direction='column' className='pageContainer'>
          <Typography variant='h5'>EHR Management</Typography>
          <Typography variant='subtitle1'>Details</Typography>
          <div style={{ height: '50vh' }}>
            <Loading size={50} />
          </div>
        </Grid>
      );
    }
    return (
      <Grid container direction='column' className='pageContainer'>
        <Typography variant='h5'>EHR Management</Typography>
        <Typography variant='subtitle1'>Details</Typography>
        <div className={classes.container}>
          <div className={classes.row}>
            <div className={classes.title}>Site Service Key: </div>
            <div style={{ paddingTop: '8px' }}>{this.state.ehrInstance.siteKey}</div>
          </div>
          <div className={classes.row}>
            <div className={classes.title}>Status:</div>
            <div style={{ paddingTop: '8px' }}>
              <div>Is Started: {this.state.ehrInstance.syncStarted ? 'True' : 'False'}</div>
              <div>Is Paused: {this.state.ehrInstance.syncPaused ? 'True' : 'False'}</div>
              <div>Is Error: {this.state.ehrInstance.hasError ? 'True' : 'False'}</div>
            </div>
          </div>
          {this.state.ehrInstance.hasError ? (
            <div className={classes.row}>
              <div className={classes.title}>Error Details:</div>
              <div style={{ paddingTop: '8px' }}>
                {this.state.ehrInstance.errorDetails}
              </div>
            </div>
          ) : null}
          {this.state.isEditTitle ? (
            <div className={classes.row}>
              <div className={classes.title}>Title:</div>
              <TextField
                id='title'
                value={this.state.title}
                onChange={this.onChange}
                error={this.state.titleError}
                helperText={this.state.titleError ? defaultErrorMsg : ''}
                fullWidth
                required
              />
            </div>
          ) : (
            <div className={classes.row}>
              <div className={classes.title}>Title:</div>
              <div style={{ paddingTop: '8px' }}>{this.state.ehrInstance.title}</div>
            </div>
          )}
          <div className={classes.container}>
            { this.state.isEditTitle ? ([
              <Button key='btn_1' className={classes.button}
                      onClick={() => this.setState({ isEditTitle: false, titleError: false })}>
                Cancel
              </Button>,
              <Button key='btn_2' variant='contained' color='secondary'
                      className={classes.button}
                      onClick={this.onSaveTitle}>
                Save
              </Button>,
            ]) : (
              <Button variant='contained' color='secondary'
                      className={classes.button}
                      onClick={this.onClickEditTitle}>
                Edit Title
              </Button>
            )}
            {this.state.ehrInstance.syncStarted && !this.state.ehrInstance.syncPaused ? (
              <Button color='secondary' variant='contained'
                      className={classes.button}
                      onClick={this.onClickPauseSync}>
                Pause Sync
              </Button>
            ) : null}
            {this.state.linkedPractitioners.length &&
            (!this.state.ehrInstance.syncStarted || this.state.ehrInstance.syncPaused) ? (
              <Button color='secondary' variant='contained'
                      className={classes.button}
                      onClick={this.onClickSync}>
                Start Sync
              </Button>
            ) : null}
            <Button color='secondary' variant='contained'
                    className={classes.button}
                    id='linkNewDoctor'
                    onClick={() => this.setState({ isLinkingNew: true })}>
              Link New Doctor
            </Button>
          </div>
        </div>
        <div className={classes.container}>
          <Typography variant='subtitle1'>Doctor</Typography>
          <Table>
            <TableBody>
              {this.state.isLinkingNew ? (
                <TableRow>
                  <TableCell>Direct Health doctor UID</TableCell>
                  <TableCell className={classes.wide}>
                    <TextField
                      id='practitionerUid'
                      value={this.state.practitionerUid}
                      onChange={this.onChange}
                      error={this.state.error}
                      helperText={this.state.error ? this.state.errorMessage || defaultErrorMsg : ''}
                      fullWidth
                      required
                      inputProps={{
                        readOnly: this.state.isSelectingPhysician,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Button className={classes.button}
                            onClick={this.onClickCancelLink}>Cancel</Button>
                    <Button variant='contained' color='secondary' disabled={this.state.isSelectingPhysician}
                            onClick={this.onLinkPractitioner}>Link</Button>
                  </TableCell>
                </TableRow>
              ) : null}
              {this.state.isLinkingNew &&
              this.state.physicians.length && this.state.isSelectingPhysician ? ([
                <TableRow key='row_1'>
                  <TableCell>Direct Health Doctor Name</TableCell>
                  <TableCell className={classes.wide}>
                    {this.state.practitioner.prettyName}
                  </TableCell>
                  <TableCell/>
                </TableRow>,
                <TableRow key='row_2'>
                  <TableCell>EHR Physician ID</TableCell>
                  <TableCell>
                    <AutoComplete
                      onSearch={this.onSearch}
                      onClear={this.onClear}
                      onSelect={this.onSelectPhysician}
                      defaultValue={this.state.searchValue}
                    />
                  </TableCell>
                  <TableCell/>
                </TableRow>,
                <TableRow key='row_3'>
                  <TableCell/>
                  <TableCell className={classes.wide}>
                    <Button variant='contained' color='secondary'
                            onClick={this.onClickManualLink}
                            disabled={!this.state.selectedPhysician}>
                      Sync Doctor to EHR
                    </Button>
                  </TableCell>
                  <TableCell/>
                </TableRow>,
              ]) : null}
              {this.state.isLinkingNew &&
              this.state.isSelectingPhysician && !this.state.physicians.length ? (
                <TableRow>
                  <TableCell colSpan='3' className={classes.centered}>
                    Physicians for this EHR Instance is not found
                  </TableCell>
                </TableRow>
              ) : null}
              {this.state.linkedPractitioners.length ?
                this.state.linkedPractitioners.map((practitioner, index) => ([
                <TableRow key={`${index}_1`}>
                  <TableCell colSpan='2' variant='head'>Linked doctor #{index + 1}</TableCell>
                  <TableCell className={classes.buttonsCell}>
                    <Button key='btn_1' className={classes.button} variant='contained' color='secondary'
                            onClick={() => this.onUnlink(index)}>Delete</Button>
                  </TableCell>
                </TableRow>,
                <TableRow key={`${index}_2`}>
                  <TableCell>Direct Health doctor UID</TableCell>
                  <TableCell className={classes.wide}>{practitioner.practitionerUid}</TableCell>
                  <TableCell/>
                </TableRow>,
                <TableRow key={`${index}_3`}>
                  <TableCell>Direct Health Doctor Name</TableCell>
                  <TableCell className={classes.wide}>{practitioner.prettyName}</TableCell>
                  <TableCell/>
                </TableRow>,
                <TableRow key={`${index}_4`}>
                  <TableCell>EHR Physician ID</TableCell>
                  <TableCell className={classes.wide}>{practitioner.ehrPhysicianId}</TableCell>
                  <TableCell/>
                </TableRow>,
                <TableRow key={`${index}_5`}>
                  <TableCell>EHR Physician Name</TableCell>
                  <TableCell className={classes.wide}>{practitioner.ehrPhysicianName}</TableCell>
                  <TableCell/>
                </TableRow>,
              ])) : (
                <TableRow>
                  <TableCell colSpan='3' className={classes.centered}>
                    Linked Doctors list is empty
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNotifySuccess: (title) => {
      dispatch(setNotifySuccess(title));
    },
    setNotifyWarning: (title) => {
      dispatch(setNotifyWarning(title));
    },
    setNotifyHidden: () => {
      dispatch(setNotifyHidden());
    },
  };
}

export default connect(null, mapDispatchToProps)(withRouter(withStyles(styles)(EHRInstanceDetails)));
