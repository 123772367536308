import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Table,
  TableRow,
  TableCell,
  Button,
  withStyles
} from "@material-ui/core";
import { capitalize } from "@material-ui/core/utils/helpers";

import {
  setNotifyHidden,
  setNotifySuccess,
  setNotifyWarning
} from "../../components/notification/NotifyUtils";
import { getSettings, saveSetting } from "../../components/utils/SettingUtils";

import Loading from "../../components/Loading";

const styles = theme => ({
  button: {
    margin: `0 ${theme.spacing.unit}px`
  },
  removeBtn: {
    margin: `0 ${theme.spacing.unit}px`,
    background: theme.palette.error.main,
    color: "#fff",
    "&:hover": {
      background: "rgba(255,87,87,0.7)"
    }
  },
  formControl: {
    width: "100%"
  }
});

const SETTINGS_NAME = {
  ios: "app.ios.min.version",
  android: "app.android.min.version",
  web: "app.web.min.version"
};

const VERSIONS = [
  "1.0.0",
  "1.1.0",
  "1.2.0",
  "1.3.0",
  "1.4.0",
  "1.5.0",
  "1.6.0",
  "1.7.0",
  "1.8.0",
  "1.9.0",
  "1.10.0",
  "1.11.0",
  "1.12.0",
  "1.13.0",
  "1.14.0",
  "1.15.0",
  "1.16.0",
  "1.17.0",
  "1.18.0",
  "1.19.0",
  "1.20.0",
  "1.21.0",
  "1.22.0",
  "1.23.0",
  "1.24.0",
  "1.25.0",
  "1.25.1",
  "1.26.0",
  "1.27.0",
  "1.28.0",
  "1.29.0",
  "1.32.0",
  "1.33.0",
  "1.34.0",
  "1.35.0",
  "1.36.0",
  "1.36.1",
  "1.37.0",
  "1.38.0",
  "1.39.0",
  "1.40.0",
  "1.41.0",
  "1.42.0",
  "1.43.0",
  "1.44.0",
  "1.45.0",
  "1.46.0",
  "1.47.0",
  "1.48.0",
  "1.49.0",
  "1.54.0",
  "1.55.0",
  "1.56.0",
  "1.57.0",
  "1.58.0",
  "1.58.1",
  "1.90.0",
  "1.90.1",
  "2.1.0",
  "2.1.1",
  "2.1.2"
];

class UpgradeVersionPage extends React.Component {
  static propTypes = {
    router: PropTypes.object,
    classes: PropTypes.object,
    setNotifyHidden: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
    setNotifySuccess: PropTypes.func.isRequired
  };

  state = {
    ios: "",
    android: "",
    web: "",
    isLoading: false,
    errors: {
      ios: false,
      android: false,
      web: false
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value, errors: { [name]: false } });
  };

  handleSave = os => {
    if (!this.validate(os)) return;
    saveSetting({
      name: SETTINGS_NAME[os],
      value: this.state[os]
    })
      .then(() => {
        this.props.setNotifySuccess(`${capitalize(os)} upgrade version set`);
      })
      .catch(e => this.props.setNotifyWarning(e.message));
  };

  validate = os => {
    let isError = false;
    if (!/[0-9]+\.[0-9]+\.[0-9]+/.test(this.state[os])) {
      isError = true;
    }

    this.setState({
      errors: Object.assign({}, this.state.errors, { [os]: isError })
    });
    return !isError;
  };

  handleRemove = os => {
    saveSetting({ name: SETTINGS_NAME[os], value: "" })
      .then(() => {
        this.setState({ [os]: "" });
        this.props.setNotifySuccess(
          `${capitalize(os)} upgrade version removed`
        );
      })
      .catch(e => this.props.setNotifyWarning(e.message));
  };

  componentWillMount() {
    this.setState({ isLoading: true });
    getSettings()
      .then(settings => {
        let ios = "";
        let android = "";
        let web = "";
        settings.forEach(item => {
          if (item.name === SETTINGS_NAME.ios) {
            ios = item.value;
          }
          if (item.name === SETTINGS_NAME.android) {
            android = item.value;
          }
          if (item.name === SETTINGS_NAME.web) {
            web = item.value;
          }
        });
        this.setState({ ios, android, web, isLoading: false });
      })
      .catch(e => {
        this.props.setNotifyWarning(e.error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { classes, router } = this.props;
    if (this.state.isLoading) {
      return (
        <Grid container direction="column">
          <Grid container direction="row" justify="space-between">
            <Typography variant="h5">Upgrade Version</Typography>
            <Button
              color="primary"
              className={classes.button}
              onClick={() => router.push("/settings")}
            >
              Go Back
            </Button>
          </Grid>
          <div style={{ height: "50vh" }}>
            <Loading size={50} />
          </div>
        </Grid>
      );
    }
    return (
      <Grid container direction="column">
        <Grid container direction="row" justify="space-between">
          <Typography variant="h5">Upgrade Version</Typography>
          <Button
            color="secondary"
            className={classes.button}
            onClick={() => router.push("/settings")}
          >
            Go Back
          </Button>
        </Grid>
        <Table>
          <TableRow>
            <TableCell variant="head">iOS min required version</TableCell>
            <TableCell>
              <FormControl className={classes.formControl}>
                <Select
                  id="ios"
                  value={this.state.ios}
                  onChange={this.handleChange}
                  inputProps={{
                    name: "ios"
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {VERSIONS.map((v, index) => (
                    <MenuItem key={index} value={v}>
                      v {v}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() => this.handleSave("ios")}
              >
                Save
              </Button>
              <Button
                className={classes.removeBtn}
                variant="contained"
                disabled={!this.state.ios}
                onClick={() => this.handleRemove("ios")}
              >
                Remove
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Android min required version</TableCell>
            <TableCell>
              <FormControl className={classes.formControl}>
                <Select
                  id="android"
                  value={this.state.android}
                  onChange={this.handleChange}
                  inputProps={{
                    name: "android"
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {VERSIONS.map((v, index) => (
                    <MenuItem key={index} value={v}>
                      v {v}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() => this.handleSave("android")}
              >
                Save
              </Button>
              <Button
                className={classes.removeBtn}
                variant="contained"
                disabled={!this.state.android}
                onClick={() => this.handleRemove("android")}
              >
                Remove
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Web min required version</TableCell>
            <TableCell>
              <FormControl className={classes.formControl}>
                <Select
                  id="web"
                  value={this.state.web}
                  onChange={this.handleChange}
                  inputProps={{
                    name: "web"
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {VERSIONS.map((v, index) => (
                    <MenuItem key={index} value={v}>
                      v {v}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() => this.handleSave("web")}
              >
                Save
              </Button>
              <Button
                className={classes.removeBtn}
                variant="contained"
                disabled={!this.state.web}
                onClick={() => this.handleRemove("web")}
              >
                Remove
              </Button>
            </TableCell>
          </TableRow>
        </Table>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNotifySuccess: title => {
      dispatch(setNotifySuccess(title));
    },
    setNotifyWarning: title => {
      dispatch(setNotifyWarning(title));
    },
    setNotifyHidden: () => {
      dispatch(setNotifyHidden());
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withStyles(styles)(UpgradeVersionPage)));
