import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  FormControl,
  FormControlLabel,
  Tooltip,
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { DateTimePicker } from "material-ui-pickers";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { cancelSmsSend, getSmsDefaultTemplate } from "../../utils/InviteUtils";
import {
  getDeliveryReport,
  getUploadsHistory,
  getUploadStats,
  saveBulkCSVFileNew,
  savePatientsUploadFile,
  savePatientsUploadFileFinal,
  processPatientsUploadFile,
  getPractitionerByID
} from "../PractitionerUtils";
import "./InvitePatients.css";
import InvitePatientsHistory from "./InvitePatientsHistory";

/**
 * InvitePatients
 */
export default class InvitePatientsNew extends React.Component {
  static propTypes = {
    router: PropTypes.object,
    params: PropTypes.shape({ id: PropTypes.string }),
    setNotifyWarning: PropTypes.func,
    setNotifySuccess: PropTypes.func,
    classes: PropTypes.object
  };

  state = {
    error: null,
    errorFinal: null,
    history: [],
    stats: {},
    isDialogOpen: false,
    type: "ehr_csv",
    fileUid: "",
    fileFields: [],
    fileImportColumns: [],
    fileUidFinal: "",
    sendToAllPatients: false,
    smsSetups: [
      {
        orderId: 1,
        overriddenTemplate: null,
        sendingDateTime: moment()
          .set("hours", 10)
          .set("minutes", 0)
          .set("seconds", 0),
        error: null
      },
      {
        orderId: 2,
        overriddenTemplate: null,
        sendingDateTime: moment()
          .set("hours", 10)
          .set("minutes", 0)
          .set("seconds", 0)
          .add(7, "days"),
        error: null
      },
      {
        orderId: 3,
        overriddenTemplate: null,
        sendingDateTime: moment()
          .set("hours", 10)
          .set("minutes", 0)
          .set("seconds", 0)
          .add(14, "days"),
        error: null
      }
    ],
    defaultMessages: ["", "", ""]
  };

  validate = () => {
    const smsSetups = this.state.smsSetups.map((item, index) => {
      if (item.sendingDateTime.hour() < 8 || item.sendingDateTime.hour() > 17) {
        return { ...item, error: "Please select time between 8AM and 6PM" };
      }

      if (
        index > 0 &&
        item.sendingDateTime < this.state.smsSetups[index - 1].sendingDateTime
      ) {
        return {
          ...item,
          error: `${index +
            1}th message should not be scheduled early ${index}th message`
        };
      }

      return { ...item, error: null };
    });

    this.setState({ smsSetups });
  };

  isSmsSetupValid = () => !this.state.smsSetups.some(item => item.error);

  handleChange = (item, prop, value) => {
    const { smsSetups } = this.state;

    if (item.orderId === 1 && prop === 'sendingDateTime') {
      smsSetups[1].sendingDateTime = moment(value).add(7, "days");
      smsSetups[2].sendingDateTime = moment(value).add(14, "days");
    }

    const newSmsSetups = smsSetups.map(sms => {
      if (sms.orderId === item.orderId) {
        return Object.assign({}, sms, { [prop]: value });
      }

      return sms;
    });

    this.setState({ smsSetups: newSmsSetups }, this.validate);
  };

  onChange(propName, event) {
    this.setState({
      [propName]: event.target.value
    });
  }

  handleChangeCheckbox(propName, event) {
    this.setState({
      [propName]: event.target.checked
    })
  }

  toggleCol2(field, value) {
    const updatedFileFields = this.state.fileFields;
    const fieldIdx = updatedFileFields.findIndex(el => el.fieldId === field);
    updatedFileFields[fieldIdx].showCol2 = value;
    if (!value) {
      updatedFileFields[fieldIdx].values[1] = -1;
    }
    this.setState({fileFields: updatedFileFields});
  }

  updateFileFields(field, type, value) {
    const updatedFileFields = this.state.fileFields;
    const fieldIdx = updatedFileFields.findIndex(el => el.fieldId === field);

    if (type === "skip") {
      updatedFileFields[fieldIdx].skip = value;
    }

    if (type === "col1") {
      updatedFileFields[fieldIdx].values[0] = parseInt(value, 10);
      if (updatedFileFields[fieldIdx].values[0] === -1) {
        updatedFileFields[fieldIdx].values[1] = -1;
      }
    }

    if (type === "col2") {
      updatedFileFields[fieldIdx].values[1] = parseInt(value, 10);
    }

    updatedFileFields[fieldIdx].preview = this.buildPreview(updatedFileFields[fieldIdx].values);
    this.setState({fileFields: updatedFileFields});
  }

  buildPreview(fieldValues) {
    const pr1 = this.state.fileImportColumns.find(el => el.index === fieldValues[0]);
    const pr2 = this.state.fileImportColumns.find(el => el.index === fieldValues[1]);

    if (pr2 && pr1) {
      return `${pr1.preview} ${pr2.preview}`;
    }
    if (pr1) {
      return `${pr1.preview}`;
    }
    if (pr2) {
      return `${pr2.preview}`;
    }
  }

  bulkCSVFile = null;

  bulkCSVFileImporterHandler = e => {
    e.preventDefault();
    this.bulkCSVFile = e.target.files[0];
    this.setState({ error: null });
  };

  processCSVFile = async e => {
    e.preventDefault();
    try {
      const resFileUpload = await savePatientsUploadFile(
        this.props.params.id,
        this.bulkCSVFile,
        this.state.type,
      );

      const res = await processPatientsUploadFile(
        this.props.params.id,
        resFileUpload.patientUploadUid,
      );
      this.setState({ fileFields: [] });
      this.setState({
        fileFields: res.fields,
        fileImportColumns: res.importColumns,
        fileUid: resFileUpload.patientUploadUid,
        fileUidFinal: "",
        errorFinal: null,
      });
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  processCSVFileFinal = async e => {
    e.preventDefault();
    try {
      const fields = [];
      this.state.fileFields.forEach((el) => {
        if (!el.skip) {
          const values = [];
          el.values.forEach((v) => {
            if (v !== -1) {
              values.push(v);
            }
          });
          if (values.length) {
            fields.push({fieldId: el.fieldId, values});
          }
        }
      });

      const resFileUpload = await savePatientsUploadFileFinal(
        this.props.params.id,
        this.state.fileUid,
        fields,
      );
      this.setState({ fileUidFinal: resFileUpload.patientUploadUid });
    } catch (error) {
      this.setState({ errorFinal: error.message });
    }
  };

  save = async e => {
    // TODO: Add a loading component and clear SMS form
    e.preventDefault();

    if (this.state.fileUidFinal &&
      confirm(this.state.sendToAllPatients ?
        "Send SMS to all users in the file including patients already invited to Direct Health?" :
        "Send SMS to all users in the file who not invited to Direct Health yet?")) {
      try {
        const res = await saveBulkCSVFileNew(
          this.props.params.id,
          this.state.fileUidFinal,
          this.state.smsSetups.map(sms => ({
            orderId: sms.orderId,
            overriddenTemplate: sms.overriddenTemplate,
            sendingDateTime: sms.sendingDateTime.format("YYYY-MM-DDTHH:mm:ss")
          })),
          this.state.sendToAllPatients
        );

        this.setState({
          history: res.uploadHistory
        });
        this.props.setNotifySuccess("Invitations sending");
      } catch (error) {
        if (error.status === 504) {
          this.props.setNotifySuccess("Validation Report Pending");
        } else {
          this.props.setNotifyWarning(
            "Error sending invites. Please try again."
          );
        }
      }
    } else if (!this.state.fileUidFinal) {
      this.setState({ error: "Select a file" });
    }
  };

  onCancel = e => {
    e.preventDefault();
    this.props.router.goBack();
  };

  getReport = async uid => {
    const newWindow = window.open("", "_blank");
    newWindow.document.write("Loading file...");

    try {
      const res = await getDeliveryReport(this.props.params.id, uid);
      const file = new Blob([res], { type: "text/csv" });
      newWindow.location.href = URL.createObjectURL(file);
    } catch (error) {
      newWindow.close();
      console.error(error);
    }
  };

  onDialogClose = () => {
    this.setState({
      isDialogOpen: false
    });
  };

  getStats = async uuid => {
    try {
      const stats = await getUploadStats(this.props.params.id, uuid);

      this.setState({
        stats,
        isDialogOpen: true,
        currentUid: uuid
      });
    } catch (error) {
      this.props.setNotifyWarning(error.message);
    }
  };

  async componentDidMount() {
    try {
      const sms = await getSmsDefaultTemplate();
      const res = await getUploadsHistory(this.props.params.id);
      const currentUser = await getPractitionerByID(this.props.params.id);

      this.setState({
        currentUser,
        history: res.uploadHistory,
        defaultMessages: [
          sms.translations && sms.translations.template_invite_pr_to_pa_sms_1,
          sms.translations && sms.translations.template_invite_pr_to_pa_sms_2,
          sms.translations && sms.translations.template_invite_pr_to_pa_sms_3,
        ],
      });
    } catch (error) {
      this.props.setNotifyWarning(error.message);
    }
  }

  handleCancel = async item => {
    const { currentUid } = this.state;
    const { id } = this.props.params;

    try {
      await cancelSmsSend(id, currentUid, item.orderId);
      const history = await getUploadsHistory(id);
      this.setState({ history: history.uploadHistory });
      this.props.setNotifySuccess(
        "The scheduled SMS invitation send has been canceled"
      );
    } catch (error) {
      this.props.setNotifyWarning(error.message);
    }
  };

  render() {
    const {
      currentUid,
      history,
      stats,
      isDialogOpen,
      currentUser
    } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <InvitePatientsHistory
          classes={classes}
          currentUid={currentUid}
          history={history}
          stats={stats}
          isDialogOpen={isDialogOpen}
          onDialogClose={this.onDialogClose}
          handleCancel={this.handleCancel}
        />

        <div className="form-pod">
          <form
            id="formInvitePatients"
            ref="formInvitePatients"
            className="form"
          >
            <div className="form-pod-title">
              <h2>
                Invite Patients for {currentUser ? currentUser.prettyName : '...'}
              </h2>
            </div>

            <div className="form-freeFieldset">
              <label htmlFor="firstname" className="form-label">
                CSV File *
              </label>

              <div className="form-flexColFieldset">
                <div>
                  <label className="tip">File ext: .csv</label>
                </div>
                <input
                  type="file"
                  name="bulkCSVFileImporter"
                  accept=".csv"
                  onChange={this.bulkCSVFileImporterHandler}
                />
                {this.state.error && (
                  <div className="errorMessage"> {this.state.error}</div>
                )}
              </div>
            </div>

            <div className="form-freeFieldset">
              <label className="form-label">Type</label>
              <div className="form-freeContainer">
                <input
                  id="ehr_csv"
                  className="form-type"
                  type="radio"
                  value="ehr_csv"
                  checked={this.state.type === "ehr_csv"}
                  onChange={e => this.onChange("type", e)}
                />
                <label htmlFor="ehr_csv">EHR import</label>
                <input
                  id="sms_csv"
                  className="form-type"
                  type="radio"
                  value="sms_csv"
                  checked={this.state.type === "sms_csv"}
                  onChange={e => this.onChange("type", e)}
                />
                <label htmlFor="sms_csv">SMS import</label>
              </div>
            </div>

            <div className="form-options">
              <Button
                style={{ minWidth: 90, marginRight: 12 }}
                variant="contained"
                color="primary"
                onClick={this.processCSVFile}
              >
                Next
              </Button>
            </div>


            {this.state.fileFields.length ? (
              <div style={{ width: "100%" }}>
                <h2>Match Columns</h2>
                <div style={{
                  borderBottom: "1px solid #cdcdcd",
                  width: "100%",
                }} />

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell />
                      <TableCell style={{ fontSize: "1.2em", textAlign: "center" }}>Skip</TableCell>
                      <TableCell style={{ fontSize: "1.2em" }}>Imported Columns</TableCell>
                      <TableCell />
                      <TableCell style={{ fontSize: "1.2em", width: "100%" }}>Preview</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.fileFields.map((field, index) => (
                      <TableRow key={index}>
                        <TableCell className="no-wrap" style={{ fontSize: "1em" }}>
                          {field.title}
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          <ArrowForwardIcon style={{ color: "#8C9DAB" }} />
                        </TableCell>
                        <TableCell className="no-wrap">
                          {!field.required ? (
                            <Checkbox
                              color="secondary"
                              onChange={ e => this.updateFileFields(field.fieldId, "skip", e.target.checked) }
                            />
                          ) : null}
                        </TableCell>
                        <TableCell className="no-wrap">
                          <TextField
                            style={{ width: "160px" }}
                            select
                            disabled={field.skip}
                            onChange={ e => this.updateFileFields(field.fieldId, "col1", e.target.value) }
                            value={typeof field.values[0] !== "undefined" ? field.values[0] : -1}
                            id="importColumnsSelector1"
                          >
                            <MenuItem value="-1">-</MenuItem>
                            {this.state.fileImportColumns.map((column) => (
                              <MenuItem value={column.index} key={column.index}>{column.title}</MenuItem>
                            ))}
                          </TextField>
                        </TableCell>
                        <TableCell className="no-wrap">
                          {typeof field.values[0] !== "undefined" && field.values[0] !== -1 && !field.showCol2 ? (
                            <div style={{ display: "flex", alignItems: "center", height: "100%", marginRight: "232px" }}>
                            <IconButton onClick={ () => this.toggleCol2(field.fieldId, true)}
                                        disabled={field.skip}>
                              <AddCircleIcon style={{ color: field.skip ? "#21CE9940" : "#21CE99" }} />
                            </IconButton>
                            </div>
                          ) : null}

                          {typeof field.values[0] !== "undefined" && field.values[0] !== -1 && field.showCol2 ? (
                          <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
                            <AddIcon style={{ color: "#8C9DAB", marginLeft: "12px", marginRight: "24px" }} />
                            <TextField
                              style={{ width: "160px", marginRight: "12px" }}
                              select
                              disabled={field.skip}
                              onChange={ e => this.updateFileFields(field.fieldId, "col2", e.target.value) }
                              value={typeof field.values[1] !== "undefined" ? field.values[1] : -1}
                              id="importColumnsSelector2"
                            >
                              <MenuItem value="-1">-</MenuItem>
                              {this.state.fileImportColumns.map((column) => (
                                <MenuItem value={column.index} key={column.index}>{column.title}</MenuItem>
                              ))}
                            </TextField>
                            <IconButton onClick={ () => this.toggleCol2(field.fieldId, false)} disabled={field.skip}>
                              <RemoveCircleIcon style={{ color: field.skip ? "#FF575740" : "#FF5757" }} />
                            </IconButton>
                          </div>
                          ) : null}
                        </TableCell>
                        <TableCell className="no-wrap" style={{ height: "48px", position: 'relative', }}>
                          {field.skip ? (
                            <div
                              style={{
                                position: 'absolute',
                                float: "left",
                                zIndex: "999",
                                height: "100%",
                                width: "100%",
                                top: "0px",
                                left: "0px",
                                backgroundColor: "rgba(255,255,255,0.75)",
                              }}/>
                          ) : null}
                          {this.buildPreview(field.values) ? (
                          <div style={{
                            display: "inline-block",
                            padding: "8px",
                            backgroundColor: "#004685",
                            color: "#fff",
                            borderRadius: "3px",
                            height: "32px",
                            fontSize: "1.2em",
                          }}>
                            {this.buildPreview(field.values)}
                          </div>
                          ) : null}
                        </TableCell>
                      </TableRow>))}
                  </TableBody>
                </Table>

                <div className="form-options">
                  <Button
                    style={{ minWidth: 90, marginRight: 12 }}
                    variant="contained"
                    color="primary"
                    onClick={this.processCSVFileFinal}
                  >
                    Next
                  </Button>
                  {this.state.errorFinal && (
                    <div className="errorMessage"> {this.state.errorFinal}</div>
                  )}
                </div>
              </div>
            ) : null}

            {this.state.fileUidFinal ? (
            <div style={{ width: "100%" }}>
            <div
              style={{
                borderBottom: "1px solid #cdcdcd",
                width: "100%",
                margin: "20px 0px"
              }}
            />

            {this.state.smsSetups.map((item, index) => (
              <Grid
                key={item.orderId}
                container
                direction="row"
                alignItems="flex-start"
                style={{ marginBottom: 20 }}
              >
                <label className="form-label">SMS Invite #{item.orderId}</label>
                <TextField
                  label="Content"
                  InputLabelProps={{
                    shrink: true
                  }}
                  style={{ flex: "1 0 auto", marginRight: 20 }}
                  value={item.overriddenTemplate || this.state.defaultMessages[index]}
                  onChange={event =>
                    this.handleChange(
                      item,
                      "overriddenTemplate",
                      event.target.value
                    )
                  }
                />
                <DateTimePicker
                  error={!!item.error}
                  helperText={item.error}
                  label="Send Date/Time"
                  emptyLabel="Any"
                  value={item.sendingDateTime}
                  onChange={date =>
                    this.handleChange(item, "sendingDateTime", date)
                  }
                  format="MM/DD/YYYY - HH:mm:ss"
                  style={{ width: "200px" }}
                />
              </Grid>
            ))}

              <Grid
                container
                direction="row"
                alignItems="flex-start"
                style={{ marginBottom: 20 }}
              >
                <label className="form-label" />
                <FormControl>
                  <Tooltip title="Includes patients already invited to Direct Health" aria-label="Includes patients already invited to Direct Health">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.sendToAllPatients}
                        onChange={(e) => this.handleChangeCheckbox('sendToAllPatients', e)}
                        value={this.state.sendToAllPatients}
                      />
                    }
                    label="Send to all patients"
                  />
                  </Tooltip>
                </FormControl>
              </Grid>

            <div className="form-options">
              <Button
                style={{ minWidth: 90, marginRight: 12 }}
                variant="contained"
                color="primary"
                onClick={this.save}
                disabled={!this.isSmsSetupValid()}
              >
                Schedule
              </Button>
              <Button
                style={{ minWidth: 90 }}
                variant="outlined"
                className={classes.deleteButton}
                onClick={this.onCancel}
              >
                Cancel
              </Button>
            </div>
            </div>
            ) : null}
          </form>
        </div>

        {this.state.history.length ? (
          <div>
            <h2>Reports</h2>
            <Table className="reports">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Scenario UID</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Links</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.history.map((report, index) => (
                  <TableRow key={index}>
                    <TableCell className="no-wrap">
                      {moment(report.date).format("YYYY-MM-DD [@] h:mm a")}
                    </TableCell>
                    <TableCell>{report.scenarioUid}</TableCell>
                    <TableCell>
                      <div className="no-wrap">
                        Completed: {report.completed ? "True" : "False"}
                      </div>
                      <div className="no-wrap">
                        Manual Upload: {report.manualUpload ? "True" : "False"}
                      </div>
                      <div className="no-wrap">
                        Validation Success:{" "}
                        {report.validationSuccess ? "True" : "False"}
                      </div>
                      <div className="no-wrap">
                        Records Uploaded: {report.nbRecordsUploaded}
                      </div>
                      <div className="no-wrap">
                        User Passed Validation: {report.nbUsersPassValidation}
                      </div>
                    </TableCell>
                    <TableCell>
                      {report.originalFileUrl ? (
                        <div className="no-wrap">
                          Original file:{" "}
                          <a
                            href={report.originalFileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            link
                          </a>
                        </div>
                      ) : null}
                      {report.validationReportUrl ? (
                        <div className="no-wrap">
                          Validation report:{" "}
                          <a
                            href={report.validationReportUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            link
                          </a>
                        </div>
                      ) : null}
                    </TableCell>
                    <TableCell className="textCenter">
                      <Button
                        color="primary"
                        onClick={() => this.getStats(report.uuid)}
                      >
                        Stats
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => this.getReport(report.uuid)}
                      >
                        Report
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : null}
      </div>
    );
  }
}
